import {render} from 'react-dom'
import {AppContainer} from 'react-hot-loader'

const initFeatureWidget = async (domEl = null, props = null) => {
  const doRender = async () => {
    const FeatureWidget = (await import('components/feature_widget.connected')).default
    render(
      (<AppContainer><FeatureWidget {...props} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/feature_widget.connected', () => { doRender() })
  }
  doRender()
}


export default initFeatureWidget
