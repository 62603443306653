import moment from 'moment-timezone'

const page = document.getElementsByTagName('html').item(0),
  pageLocale = page.getAttribute('lang'),
  pageTimezone = page.getAttribute('timezone')

if (pageLocale) {
  moment.locale(pageLocale)
} else {
  console.error('No locale found on page')
}

if (pageTimezone) {
  moment.tz(pageTimezone)
} else {
  console.error('No timezone found on page')
}

export const parseApiTimeObject = (object) => {
  return moment.tz(
    {
      ...object,
      month: object.month - 1 // first month is 0, can you believe it?
    },
    pageTimezone
  )
}

export default moment
