import React from 'react'

const ArticleMini = ({height = 32, fill = '#4896EC', background = '#fff'}) => {
  const width = Math.floor(height * 5 / 4)

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 28">
      <defs></defs>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g>
          <path
            d="M2.8,0 C1.253,0 0.014,1.253 0.014,2.8 L0,25.2 C0,26.747 1.239,28 2.786,28 L19.6,28 C21.147,28 22.4,26.747 22.4,25.2 L22.4,8.4 L14,0 L2.8,0 Z M12.6,9.8 L12.6,2.1 L20.3,9.8 L12.6,9.8 Z"
            fill={fill}>
          </path>
          <path
            d="M12.1465936,20.0463355 L10.0313103,17.2259578 L8.40077929,19.3412411 L7.21093264,17.9310522 L5.58040161,20.0463355 L12.1465936,20.0463355 Z M13.5567824,14.4055801 L13.5567824,20.0463355 C13.5567824,20.545777 13.3658195,20.9864614 12.983893,21.3683871 C12.6019673,21.7503129 12.1612829,21.9412766 11.6618414,21.9412766 L6.021086,21.9412766 C5.52164374,21.9412766 5.08830437,21.7503129 4.72106788,21.3683871 C4.35383139,20.9864614 4.17021277,20.545777 4.17021277,20.0463355 L4.17021277,14.4055801 C4.17021277,13.9061379 4.35383139,13.4654542 4.72106788,13.0835277 C5.08830437,12.7016012 5.52164374,12.5106383 6.021086,12.5106383 L11.6618414,12.5106383 C12.1612829,12.5106383 12.6019673,12.7016012 12.983893,13.0835277 C13.3658195,13.4654542 13.5567824,13.9061379 13.5567824,14.4055801 Z M15.4517235,21.9412766 L15.4517235,20.0463355 L18.9720096,20.0463355 L18.9720096,21.9412766 L15.4517235,21.9412766 Z M18.9720096,12.5106383 L18.9720096,14.4055801 L15.4517235,14.4055801 L15.4517235,12.5106383 L18.9720096,12.5106383 Z M18.9720096,18.1513937 L15.4517235,18.1513937 L15.4517235,16.3005212 L18.9720096,16.3005212 L18.9720096,18.1513937 Z"
            fill={background}
            fillRule="nonzero">
          </path>
        </g>
      </g>
    </svg>
  )
}

export default ArticleMini
