import {buildTranslator} from 'utils/translator'
const t = buildTranslator(['Shortcuts'])
import List from './list'

const ShortcutsList = [
  {shortcut: ['/'], description: 'Start global search'},
  {shortcut: ['esc'], description: 'Close a popup'},
  {shortcut: ['?'], description: 'Open this popup'}
]
const TranslatedShortcutList = ShortcutsList.map(
  ({shortcut, description}) => ({shortcut, description: t(description)})
)

export default () => <List shortcuts={TranslatedShortcutList} />
