import {buildTranslator} from 'utils/translator'
const t = buildTranslator(['Shortcuts'])
import List from './list'

const ShortcutsList = [
  {shortcut: ['meta', 'b'], description: 'Bold'},
  {shortcut: ['meta', 'i'], description: 'Italics'},
  {shortcut: ['meta', 'u'], description: 'Underline'},
  {shortcut: ['meta', 'k'], description: 'Add link (select first)'},

  {shortcut: ['option', '1'], description: 'Toggle ordered list item'},
  {shortcut: ['option', '*'], description: 'Toggle unordered list item'},
  {shortcut: ['option', '#'], description: 'Toggle header'},
  {shortcut: ['option', ','], description: 'Toggle block quote'},
  {shortcut: ['option', 'h'], description: 'Highlight selection'}
]
const TranslatedShortcutList = ShortcutsList.map(
  ({shortcut, description}) => ({shortcut, description: t(description)})
)

export default () => <List shortcuts={TranslatedShortcutList} />
