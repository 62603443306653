const initArticlesEditor = async(domEl, props = {}) => {
  const render = await import('./index')
  render.default({domEl, ...props, editorEnabled: true})
}

const initArticlesViewer = async(domEl, props = {}) => {
  const render = await import('./index')
  render.default({domEl, ...props, editorEnabled: false})
}

export {initArticlesEditor, initArticlesViewer}
export default initArticlesEditor
