import React from 'react'
import moment from 'utils/moment'
import classNames from 'classnames'

class Time extends React.Component {
  static defaultProps = {format: 'LLL'};

  timefromProps = () => {
    const rawTime = this.props.time,
      parseType = typeof rawTime

    if (parseType === 'number') {
      return moment.unix(rawTime)
    }

    return moment(rawTime)
  };

  timeBody = (time) => {
    if (this.props.children) {
      return this.props.children
    } else if (this.props.format === 'relative') {
      return time.fromNow()
    }

    return time.format(this.props.format)
  };

  render() {
    const time = this.timefromProps(),
      className = classNames('timestamp', this.props.className),
      {style = {}} = this.props

    return (
      <time
        style={style}
        className={className}
        dateTime={time.format()}
        title={time.format('LLL')}>
        {this.timeBody(time)}
      </time>
    )
  }
}

const RelativeTime = (props) => {
  return <Time {...props} format="relative" />
}

export {Time, RelativeTime}
export default Time
