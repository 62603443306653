import {buildTranslator} from 'utils/translator'
const t = buildTranslator(['Shortcuts'])
import List from './list'

const ShortcutsList = [
  {shortcut: ['space'], description: 'Open selected item'},
  {shortcut: ['del'], description: 'Delete selected item(s)'},
  {shortcut: ['c'], description: 'Create folder'},
  {shortcut: ['l'], description: 'Switch layout'},
  {shortcut: ['enter'], description: 'Edit name of selected item(s)'},
  {shortcut: ['f'], description: 'Open filters'},
  {shortcut: ['r'], description: 'Go to top folder'},
  {shortcut: ['m'], description: 'Move selected item(s)'},
  {shortcut: ['←','↑','↓','→'], description: 'Change selection', combo: false},
  {shortcut: ['meta', 'a'], description: 'Select all'},
  {shortcut: ['shift'], description: 'Hold down the Shift key to add or remove items from the selection'}
]
const TranslatedShortcutList = ShortcutsList.map(
  ({description, ...props}) => ({description: t(description), ...props})
)

export default () => <List shortcuts={TranslatedShortcutList} />
