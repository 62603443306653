import API from 'utils/api'
import gql from 'utils/gql'

const graphqlQuery = gql`
  query globalSearch($query: String!, $limit: Int) {
    globalSearch(input: {query: $query}, limit: $limit) {
      id
      name
      type
      app { id name icon { name } }
      thumbnail { uri(avatar: true) }
    }
  }
`

const searchResources = ({query, limit = 10}) => {
  return API.graphql({
    query: graphqlQuery,
    variables: {
      query,
      limit
    }
  }).then(({globalSearch}) => globalSearch)
}

export default searchResources
