import Select from 'react-select'
import FileMini from 'elements/icon/file_mini'
import FolderMini from 'elements/icon/folder_mini'
import ArticleMini from 'elements/icon/article_mini'
import Icon from 'elements/icon'
import {buildTranslator} from 'utils/translator'

const t = buildTranslator(['global'])

import style from './style.css?modules'

const FOREGROUND_COLOR = '#FFFFFF',
  BACKGROUND_COLOR = '#4896EC'

export const DO_SEARCH_ID = 'do_search'

export const ALL_RESULTS_OPTION = {
  name: 'Search...',
  type: 'all_results',
  id:   DO_SEARCH_ID
}

const optionDetailDimensions = {
  size: 22,
  verticalPadding: 10
}

const optionDetailStyle = {
  // include padding in Height, because of border-box sizing
  height: optionDetailDimensions.size + (2 * optionDetailDimensions.verticalPadding),
  paddingTop: optionDetailDimensions.verticalPadding,
  paddingBottom: optionDetailDimensions.verticalPadding
}

const alignStyle = {
  minWidth: 30,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'inherit'
}

const optionDetailThumbnailStyle = {
  height: optionDetailDimensions.size,
  width: optionDetailDimensions.size
}

const optionDetailTextStyle = {
  maxWidth: '85%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'inline-block'
}

const AllResultsOption = ({isFocused}) => {
  return (
    <div style={{textAlign: 'right'}}>
      {t(ALL_RESULTS_OPTION.name)}
      <div style={{marginLeft: 7, display: 'inline-block'}} className={isFocused ? style.arrow : ''}>
        <Icon name="right-open-big" />
      </div>
    </div>
  )
}

export const SearchOption = (props) => {
  const {option, inputValue, isFocused} = props

  if (option.type === ALL_RESULTS_OPTION.type) {
    const optionStyle = isFocused ? {backgroundColor: BACKGROUND_COLOR, color: FOREGROUND_COLOR} : {backgroundColor: '#EEEEEE'}
    return (
      <Select.Option {...props} option={{...option, style: {...props.option.style, ...optionStyle, paddingRight: 0}}}>
        <AllResultsOption isFocused={isFocused} />
      </Select.Option>
    )
  } else {
    const optionStyle = isFocused ? {backgroundColor: BACKGROUND_COLOR, color: FOREGROUND_COLOR} : {}
    return (
      <Select.Option {...props} option={{...option, style: {...props.option.style, ...optionStyle, paddingTop: 0, paddingBottom: 0}}}>
        <OptionDetail {...props} />
      </Select.Option>
    )
  }
}

const fillColor = (isFocused) => {
  return {
    background: isFocused ? BACKGROUND_COLOR : FOREGROUND_COLOR,
    main: isFocused ? FOREGROUND_COLOR : BACKGROUND_COLOR
  }
}

const ResourceTypeIcon = ({type, isFocused}) => {
  const {main, background} = fillColor(isFocused)

  const components = {
    file: FileMini,
    folder: FolderMini,
    post: ArticleMini,
    article: ArticleMini
  }

  const Component = components[type] || FileMini

  return (
    <div style={{...optionDetailThumbnailStyle, ...alignStyle}}>
      <Component
        fill={main}
        background={background}
        height={optionDetailDimensions.size} />
    </div>
  )
}

const AppIcon = ({isFocused, name}) => {
  return (
    <Icon
      style={{
        color: fillColor(isFocused).main,
        opacity: 1,
        fontSize: 18,
        marginRight: 0,
        ...alignStyle
      }}
      name={name} />
  )
}

const OptionDetailThumbnail = ({app: {id, icon: {name: iconName}}, type, isFocused, thumbnail}) => {
  switch (type) {
    case 'profile':
      if (thumbnail) {
        return (
          <div style={alignStyle}>
            <img src={thumbnail.uri} style={optionDetailThumbnailStyle} />
          </div>
        )
      }

      return <AppIcon isFocused={isFocused} name={iconName} />
    case 'file':
    case 'folder':
    case 'article':
      return <ResourceTypeIcon isFocused={isFocused} type={type} />
    case 'post':
      if (id === 'newsletters') {
        return <AppIcon isFocused={isFocused} name={iconName} />
      }

      return <ResourceTypeIcon isFocused={isFocused} type={type} />
    default:
      return <AppIcon isFocused={isFocused} name={iconName} />
  }
}

const OptionDetail = ({option, isFocused}) => {
  const {type, name, app, thumbnail} = option

  return (
    <div
      title={name}
      className="media"
      style={optionDetailStyle}>
      <div
        className='media-left'>
        <OptionDetailThumbnail
          app={app}
          isFocused={isFocused}
          type={type}
          thumbnail={thumbnail} />
      </div>
      <div
        className='media-body'
        style={optionDetailTextStyle}>
        {name}
      </div>
    </div>
  )
}
