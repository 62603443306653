import React from 'react'

const Icon = (props) => {
  const {name, marginless, margin, className, style, ...rest} = props

  var iconClass = `icon icon-${name}`

  if (margin === false || marginless === true) iconClass = `${iconClass} marginless`
  if (className)        iconClass = `${iconClass} ${className}`

  return <i {...rest} className={iconClass} style={style} />
}

export default Icon
