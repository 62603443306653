const SearchIcon = ({height = 32, fill = 'currentColor', style = {}}) => {
  const width = Math.floor(height * 13 / 16)

  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 17 21">
      <defs></defs>
      <g id="Page-1" stroke="none" fill={fill} fillRule="evenodd">
          <g id="topbar" transform="translate(-819.000000, -608.000000)">
              <g id="top-purple" transform="translate(35.000000, 574.000000)">
                  <g id="left" transform="translate(52.000000, 7.000000)">
                      <g id="search" transform="translate(383.000000, 12.000000)">
                          <path d="M365.726491,29.6630048 L362.424251,26.3607649 C362.275203,26.211717 362.07316,26.1289126 361.861181,26.1289126 L361.321296,26.1289126 C362.235457,24.9597143 362.778654,23.489108 362.778654,21.8893268 C362.778654,18.0836362 359.695017,15 355.889327,15 C352.083636,15 349,18.0836362 349,21.8893268 C349,25.6950175 352.083636,28.7786537 355.889327,28.7786537 C357.489108,28.7786537 358.959714,28.2354568 360.128913,27.3212961 L360.128913,27.8611808 C360.128913,28.0731601 360.211717,28.2752029 360.360765,28.4242508 L363.663005,31.7264907 C363.974349,32.0378352 364.4778,32.0378352 364.785833,31.7264907 L365.723178,30.7891447 C366.034523,30.4778002 366.034523,29.9743494 365.726491,29.6630048 Z M355.889327,26.1289126 C353.547618,26.1289126 351.649741,24.2343477 351.649741,21.8893268 C351.649741,19.5476182 353.544306,17.6497411 355.889327,17.6497411 C358.231036,17.6497411 360.128913,19.544306 360.128913,21.8893268 C360.128913,24.2310355 358.234348,26.1289126 355.889327,26.1289126 Z" id="Shape"></path>
                      </g>
                  </g>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default SearchIcon
