import xss from 'xss'

const tags = ['a', 'strong', 'em', 'strike', 'b', 'i', 'u', 'p', 'ol', 'ul', 'li', 'br', 'img', 'sub', 'sup', 'blockquote', 'table', 'tr', 'tbody', 'thead', 'tfoot', 'td', 'th', 'caption', 'div', 'code', 'pre', 'address', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'hr', 'dd', 'dl', 'dt', 'cite', 'abbr', 'acronym', 'del', 'ins', 'bdo', 'col', 'colgroup', 'dfn', 'kbd', 'q', 'samp', 'small', 'tt', 'var', 'big', 'span']

const attrs = ['id', 'style', 'title', 'dir', 'lang', 'xml:lang', 'name', 'href', 'target', 'title', 'src', 'alt', 'width', 'height', 'cellspacing', 'cellpadding', 'rules', 'summary', 'rowspan', 'colspan', 'scope', 'datetime', 'cite', 'char', 'charoff', 'span', 'abbr', 'align']

const whiteList = tags.reduce((memo, tag) => ({...memo, [tag]: attrs}), {})

const stripOptions = {
  whiteList: [],
  stripIgnoreTag: true,
  stripIgnoreTagBody: ['script'],
  allowCommentTag: false
}

const richXssOptions = {
  ...stripOptions,
  whiteList
}

const RichHTML = ({rawHTML}) => {
  const safeHtml = richHtmlSafe(rawHTML)
  return <span dangerouslySetInnerHTML={{__html: safeHtml}} />
}

const richHtmlSafe = (rawHTML) => {
  const html = rawHTML.replace(/\n+/, '<br />')
  return xss(html, richXssOptions)
}

const stripHtmlSafe = (rawHTML) => {
  return xss(rawHTML, stripOptions)
}

const htmlSafe = (rawHTML) => {
  return xss(rawHTML)
}

export default htmlSafe
export {RichHTML, richHtmlSafe, stripHtmlSafe}
