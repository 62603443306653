import React, {lazy, Suspense} from 'react'
import Loader from 'elements/loader'

import {Router, Route, IndexRoute, withRouter} from 'react-router'
import translate, {Translator} from 'utils/translator'
import {useBasename, useQueries, createHistory} from 'history'
import {useRouterHistory} from 'react-router'

const ProfileAppLayout = lazy(() => import(
  /* webpackChunkName: "profile_app" */
  'components/profile/app_layout.connected'
))

const navigationInfoToLink = ({tab, section, editing}) => {
  return [
    editing ? '_edit' : null,
    tab,
    section
  ].filter((p) => p).join('/')
}

const RoutedProfileAppLayout = withRouter(class extends React.Component {
  currentNavigationProps = () => {
    const props = this.props.routes.map((r) => (r.props || {})).reduce((memo, props) => {
      return {...memo, ...props}
    }, this.props.params)

    return {
      tab: props.tab,
      section: props.section,
      editing: props.editing,
      basepath: props.basepath
    }
  };

  updateNavigation = (newNav) => {
    this.props.router.push(
      this.navigationLinkGenerator(newNav)
    )
  };

  navigationLinkGenerator = (newNav, absolute = false) => {
    const {basepath, ...subNav} = this.currentNavigationProps()
    newNav = {
      ...subNav,
      ...newNav
    }
    const {tab, section, editing} = newNav

    if (absolute) {
      return `${basepath}/${navigationInfoToLink(newNav)}`
    } else {
      return navigationInfoToLink(newNav)
    }
  };

  linkGenerator = (newNav) => {
    return this.navigationLinkGenerator(newNav, true)
  };

  render() {
    const props = this.props.routes.map((r) => (r.props || {})).reduce((memo, props) => {
      return {...memo, ...props}
    }, this.props.params)

    return (
      <Suspense fallback={<Loader />}>
        <ProfileAppLayout
          {...props}
          navigationLinkGenerator={this.linkGenerator}
          updateNavigation={this.updateNavigation}
        />
      </Suspense>
    )
  }
})

class ProfileApp extends React.Component {
  constructor(props) {
    super(props);
    const basename = window.location.pathname.split('/', 3).join('/'),
      history = useRouterHistory(createHistory)({basename})

    this.state = {
      browserHistory: history,
      basepath: basename
    };
  }

  render() {
    return (
      <Router ref="router" history={this.state.browserHistory}>
        <Route
          component={RoutedProfileAppLayout}
          props={{...this.props, basepath: this.state.basepath}}>
          <Route path="_edit" props={{editing: true}}>
            <Route path=":tab">
              <Route path=":section" />
            </Route>
          </Route>
          <Route path=":tab" props={{editing: false}}>
            <Route path=":section" />
          </Route>
          <Route path="*" />
        </Route>
      </Router>
    )
  }
}

export {RoutedProfileAppLayout}
export default ProfileApp
