import Mousetrap from 'mousetrap'

import Modal from 'elements/modal'
import {buildTranslator} from 'utils/translator'
const t = buildTranslator(['Shortcuts'])

import GlobalShortcuts from './shortcuts/global'
import MediaShortcuts from './shortcuts/media'
import ArticlesShortcuts from './shortcuts/articles'

const sectionStyle = {
  borderBottom: '1px solid #999',
  paddingBottom: 3,
  marginBottom: 1
}

class ShortcutsModal extends React.Component {

  constructor(props, context) {
    super(props, context)
    this.state = { open: false }
  }

  componentDidMount() {
    $(window.document).on('click', '#keyboard-shortcuts-link', () => {
      this.setState((s) => ({...s, open: true}))
    })
    Mousetrap.bind('?', e => {
      e.preventDefault && e.preventDefault()
      this.setState((s) => ({...s, open: true}))
    })
  }

  closeModal = () => {
    this.setState((s) => ({...s, open: false}))
  }

  render() {
    return (
      <Modal
        isOpen={this.state.open}
        onRequestClose={this.closeModal}
        title={t('Keyboard shortcuts')}>
        <table style={{minWidth: 800}}><tbody>
          <tr>
            <td style={{padding: 10}} colSpan={2}>
              <h3 style={sectionStyle}>{t('General')}</h3>
              <GlobalShortcuts />
            </td>
          </tr>
          <tr>
            <td style={{width: '50%', verticalAlign: 'top', borderRight: '1px solid #CCC', padding: 10}}>
              <h3 style={sectionStyle}>{t('Media')}</h3>
              <MediaShortcuts />
            </td>
            <td style={{width: '50%', verticalAlign: 'top', padding: 10}}>
              <h3 style={sectionStyle}>{t('Article Editor')}</h3>
              <ArticlesShortcuts />
            </td>
          </tr>
        </tbody></table>
      </Modal>
    )
  }
}

export default ShortcutsModal
