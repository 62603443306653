import React from 'react'

const FileMini = ({height = 32, fill = '#4896EC'}) => {
  const width = Math.floor(height * 13 / 16)

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 32">
      <defs></defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <path
          d="M3.2,0 C1.432,0 0.016,1.432 0.016,3.2 L0,28.8 C0,30.568 1.416,32 3.184,32 L22.4,32 C24.168,32 25.6,30.568 25.6,28.8 L25.6,9.6 L16,0 L3.2,0 Z M14.4,11.2 L14.4,2.4 L23.2,11.2 L14.4,11.2 Z"
          fill={fill}>
        </path>
      </g>
    </svg>
  )
}

export default FileMini
