import {isEmpty, isArray} from 'lodash'
import * as QueryParser from 'query-string-parser'
import FileApi from 'fileapi'

const HEADERS = {
  'Accept': 'application/json, DATETIME_FORMAT=object',
  'Content-Type': 'application/json; charset=utf-8'
}

const OPTIONS = {
  headers: HEADERS,
  credentials: 'same-origin',
}

const options = (headers = {}) => {
  return {
    ...OPTIONS,
    headers: {...OPTIONS.headers, ...headers}
  }
}

const apiUrl = (uri, params = {}) => {
  if (uri.startsWith('http')) return uri

  if (!uri.match(/\/api\//) && !uri.match(/^\//)) {
    uri = `/api/${uri}`
  }

  if (uri.match(/\/api\/notifications/)) {
    params = {
      heartbeat: (window.TrustedPlatform || {heartbeat: false}).heartbeat,
      ...params
    }
  }

  if (params !== {}) {
    const joiner = uri.includes('?') ? '&' : '?'
    uri = `${uri}${joiner}${QueryParser.toQuery(params)}`
  }

  return uri
}

const parseResponseType = (responseType) => {
  return (response) => {
    return response[responseType]()
  }
}

const handleApiError = (response) => {
  if (response.ok) return response

  if (response.status === 403 || response.status === 401) {
    if (!response.url.match(/\/api\/actions/)) {
      window.location.href = '/login'
    }
  } else {
    throw (new Error(response.statusText))
  }
}

const get = (uri, {headers = {}, responseType = 'json'} = {}) => {
  return fetch(
      apiUrl(uri),
      options(headers)
    )
    .then(handleApiError)
    .then(result => {
      return parseResponseType(responseType)(result)
    })
}

const patch = (uri, body, {headers = {}, responseType = 'json'} = {}) => {
  body = isArray(body) ? {_json: body} : body

  return fetch(
      apiUrl(uri),
      {
        ...options(headers),
        method: 'PATCH',
        body: JSON.stringify(body)
      }
    )
    .then(handleApiError)
    .then(result => {
      return parseResponseType(responseType)(result)
    })
}

const post = (uri, body, {headers = {}, responseType = 'json'} = {}) => {
  return fetch(
      apiUrl(uri),
      {
        ...options(headers),
        method: 'POST',
        body: JSON.stringify(body)
      }
    )
    .then(handleApiError)
    .then(result => {
      return parseResponseType(responseType)(result)
    })
}

const upload = (uri, file, data = {}) => {
  const {onFileProgress = () => {}, headers = {}, responseType = 'json', fileParam = 'file'} = data

  return new Promise((resolve, reject) => {
    FileApi.upload({
      url: apiUrl(uri),
      files: {[fileParam]: [file]},
      complete(err, xhr, file, options) {
        if (err) {
          reject(err)
        } else {
          const {files: [persistedFile]} = JSON.parse(xhr.response)
          resolve({persistedFile})
        }
      },
      fileprogress(evt, file, xhr, options) {
        var pr = evt.loaded/evt.total * 100;
        console.log('Progress:', pr )
        onFileProgress({progress: pr})
      },
      filecomplete: () => {}
    })
  })
}

const put = (uri, body, {headers = {}, responseType = 'json'} = {}) => {
  body = isArray(body) ? {_json: body} : body

  return fetch(
      apiUrl(uri),
      {
        ...OPTIONS,
        method: 'PUT',
        body: JSON.stringify(body)
      }
    )
    .then(handleApiError)
    .then(result => {
      return parseResponseType(responseType)(result)
    })
}

const deleteAction = (uri, body, {headers = {}, responseType = 'json'} = {}) => {
  return fetch(
      apiUrl(uri),
      {
        ...options(headers),
        method: 'DELETE',
        body: JSON.stringify(body)
      }
    )
    .then(handleApiError)
    .then(result => {
      result.text().then((text) => {
        if (isEmpty(text)) {
          return Promise.resolve(true)
        } else {
          return parseResponseType(responseType)(result)
        }
      })
    })
}

const graphql = (args) => {
  return post('/graphql', args).then(({data, errors}) => {
    return data ? Promise.resolve(data) : Promise.reject(errors)
  })
}

export default { get, patch, post, upload, delete: deleteAction, put, apiUrl, graphql }
