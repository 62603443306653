import React from 'react'

const FolderMini = ({height = 32, fill = '#4896EC'}) => {
  const width = Math.floor(height * 5 / 4)

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 32">
      <defs></defs>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <path
          d="M15.9064332,0 L19.9298245,4.02339291 L35.9298237,4.02339291 C36.9902533,4.02339291 37.9103314,4.42885059 38.6900581,5.23976595 C39.4697848,6.05068291 39.8596489,6.9863546 39.8596489,8.04678422 L39.8596489,27.9766087 C39.8596489,29.0370367 39.4697848,29.97271 38.6900581,30.7836254 C37.9103314,31.5945407 36.9902533,32 35.9298237,32 L3.92982526,32 C2.86939564,32 1.94931749,31.5945407 1.16959081,30.7836254 C0.389864137,29.97271 0,29.0370367 0,27.9766087 L0,4.02339291 C0,2.96296329 0.389864137,2.0272916 1.16959081,1.21637464 C1.94931749,0.40545768 2.86939564,0 3.92982526,0 L15.9064332,0 Z"
          id="Shape"
          fill={fill}>
        </path>
      </g>
    </svg>
  )
}

export default FolderMini
