const keyStyle = {
  marginRight: 3,
  marginLeft: 3,
  border: '1px solid #c6cbd1',
  backgroundColor: '#fafbfc',
  borderRadius: 3,
  padding: '0px 5px 0px 5px',
  boxShadow: '0px 1px 0px #959da5',
  display: 'inline-block',
  fontFamily: 'monospace'
}

const isApple = /Mac|iPod|iPhone|iPad/.test(navigator.platform)

const replaceMetaAndOptionKeys = (key) => {
  if (key === 'meta')
    return isApple ? 'cmd|⌘' : 'Ctrl'
  else if (key === 'option')
    return isApple ? 'alt|⌥' : 'Alt'
  else
    return key
  end
}

const Keys = ({keys, combo = true}) => {
  const joiner = combo ? '+' : ''

  return keys.flatMap((key, i) =>
    [
      i === 0 ? '' : joiner,
      <span style={keyStyle}>{replaceMetaAndOptionKeys(key)}</span>
    ]
  )
}

export default ({shortcuts}) => (
  <div>
    <table><tbody>
      {
        shortcuts.map(({shortcut, description, combo = true}) => (
          <tr>
            <td style={{padding: 5, textAlign: 'right', minWidth: 130}}>
              <Keys keys={shortcut} combo={combo} />
            </td>
            <td style={{padding: 5}}>
              <div>{description}</div>
            </td>
          </tr>
        ))
      }
    </tbody></table>
  </div>
)
