import Mousetrap from 'mousetrap'

import Select from 'react-select'
import {debounce} from 'lodash'
import {handleMiddlewareError} from 'utils/error_catcher'
import track from 'utils/track'
import SearchIcon from 'elements/icon/search_icon'
import {buildTranslator} from 'utils/translator'
import search from './middleware/search'

import {
  ALL_RESULTS_OPTION,
  DO_SEARCH_ID,
  SearchOption
} from './search/option'

const t = buildTranslator(['global'])

const LIMIT = 10

const componentStyle = {
  maxWidth: 350,
  minWidth: 100
}

const menuContainerStyle = {
  ...componentStyle,
  whiteSpace: 'normal',
  maxHeight: 'inherit'
}

const SearchPlaceholder = () => (
  <div>
    <p>
      {t('Use arrow and Enter keys to select an option')}
    </p>
  </div>
)

const SearchNoResults = () => (
  <div>
    {t('No matches found')}
  </div>
)

const SearchLoading = () => (
  <div>
    {t('Searching...')}
  </div>
)

const addDefaultOption = (options) => {
  return [ALL_RESULTS_OPTION, ...options]
}

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.debouncedLoadOptions = debounce(this.loadOptions, 250)

    this.state = {
      query: '',
      options: []
    }
  }

  setSearchRef = (ref) => {
    Mousetrap.bind('/', e => {
      e.preventDefault && e.preventDefault()
      window.showMainMenu && window.showMainMenu()
      ref.focus()
    })
  }

  loadOptions = (query, callback) => {
    this.setState((s) => ({...s, query}))

    if (!query || query === '') {
      callback(null, {options: [], complete: true})
    } else {
      search({query, limit: LIMIT}).then((options) => {
        this.setState((s) => ({...s, options}))

        callback(null, {
          options: addDefaultOption(options),
          complete: true
        })
      }).catch(handleMiddlewareError)
    }
  }

  renderArrow = () => {
    return (
      <div onClick={this.goToSearchPage}>
        <SearchIcon style={{color: '#8B8B8B', verticalAlign: 'middle'}} height={18} />
      </div>
    )
  }

  onSelectOption = (result) => {
    if (!result || result.id === DO_SEARCH_ID) {
      this.goToSearchPage()
    } else {
      const {query, options} = this.state

      track('global_search_suggestion_select', {
        resource_uuid: result.id,
        query_size: query.length,
        index: options.findIndex(o => o.id === result.id),
        suggestion_count: options.length
      })
      this.props.onSelectResult(result)
    }
  }

  goToSearchPage = () => {
    this.props.onSearch(this.state.query)
  }

  render() {
    return (
      <Select.Async
        ref={this.setSearchRef}
        valueKey='id'
        labelKey='name'
        cache={false}
        autoload={false}
        openOnFocus
        onBlurResetsInput={false}

        style={componentStyle}
        menuContainerStyle={menuContainerStyle}
        menuStyle={{maxHeight: 'inherit'}}

        loadOptions={this.debouncedLoadOptions}
        filterOptions={(options) => options}

        optionComponent={SearchOption}
        arrowRenderer={this.renderArrow}
        searchPromptText={<SearchPlaceholder />}
        loadingPlaceholder={<SearchLoading />}
        noResultsText={<SearchNoResults />}
        placeholder={t(ALL_RESULTS_OPTION.name)}

        onChange={this.onSelectOption} />
    )
  }
}

export default Search
