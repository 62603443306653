import API from 'utils/api'

const track = (name, data = {}) => {
  let params = {}

  if (data.resource_uuid) {
    params.resource_uuid = data.resource_uuid
    delete data.resource_uuid
  } else if (data.resource_uuids) {
    params.resource_uuids = data.resource_uuids
    delete data.resource_uuids
  }

  API.post('actions', {name, data, ...params}, {responseType: 'text'})
}

export default track
