import React, {lazy, Suspense} from 'react'
import Loader from 'elements/loader'
import {render, unmountComponentAtNode} from 'react-dom'

import * as SettingsStore from 'apps/resources/middleware/settings'
window.SettingsStore = SettingsStore

import moment from 'utils/moment'
window.moment = moment

window.initTinyMceWebpack = async (options) => {
  const loaderEl = document.getElementById(`${options.selector}-loader`)

  if (loaderEl) {
    render(<Loader />, loaderEl)
  }

  const init = (await import('utils/tinymce')).default
  init(options)

  if (loaderEl) {
    unmountComponentAtNode(loaderEl)
  }
}

window.initTinyMceImageBrowser = (domEl, props) => {
  const doRender = async () => {
    const ImageBrowser = (await import('components/tinymce_image_browser')).default
    render(
      <ImageBrowser {...props} />,
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/tinymce_image_browser', () => { doRender() })
  }
  doRender()
}

import track from 'utils/track'
window.track = track

import htmlSafe from 'utils/html_safe'
window.convertToHtmlSafe = htmlSafe

import initNotifications from 'apps/notifications/entry'
window.initNotifications = initNotifications

import initSocialBox from 'apps/social_box/entry'
window.initSocialBox = initSocialBox

import initProfiles from 'apps/profiles/entry'
window.initProfiles = initProfiles

window.initTwoFactorSmsSetup = (domEl, props) => {
  const doRender = async () => {
    const Component = lazy(() => import('components/two_factor_sms_setup'));
    render(
      (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      ), domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/two_factor_sms_setup', () => { doRender() })
  }
  doRender()
}

window.sendTwoFactorSMS = (domEl, props) => {
  const doRender = async () => {
    const Component = lazy(() => import('components/two_factor_sms_send_button'));
    render(
      (
        <Suspense fallback={<span>...</span>}>
          <Component {...props} />
        </Suspense>
      ), domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/two_factor_sms_send_button', () => { doRender() })
  }
  doRender()
}

window.initReactVideoPlayer = (domEl, props) => {
  const VideoWithContainerDimensions = lazy(() => import('components/video_player'))
  render(
    (
      <Suspense fallback={<Loader />}>
        <VideoWithContainerDimensions {...props} />
      </Suspense>
    ), domEl
  )
}

window.initProfileBirthAndDeath = async (domEl, props) => {
  const render = await import('components/profile_birth_and_death.entry').default
  render(domEl, props)
}


import initProfileApp from 'components/profile_app.entry'
window.initProfileApp = initProfileApp


import initMedia from 'apps/media/entry'
window.initMedia = initMedia

window.initMediaEditModal = async (domEl, props = {}) => {
  const {initMediaEditModal} = await import('apps/media')
  return initMediaEditModal({domEl, ...props})
}

window.initMediaSidebarInfo = async (domEl, props = {}) => {
  const {initMediaSidebarInfo} = await import('apps/media')
  return initMediaSidebarInfo({domEl, ...props})
}

window.refreshResource = async ({resourceId}) => {
  const {refreshResource} = await import('apps/media')
  return refreshResource({resourceId})
}

import {initArticlesEditor, initArticlesViewer} from 'apps/article/entry'

window.initArticlesEditor = initArticlesEditor
window.initArticlesViewer = initArticlesViewer

import initStoryEditor from 'apps/story/entry'
window.initStoryEditor = initStoryEditor

import initFeatureWidget from 'components/feature_widget.entry'
window.initFeatureWidget = initFeatureWidget

import {AppContainer} from 'react-hot-loader'

window.initEventGuests = async (domEl, props) => {
  const doRender = async () => {
    const EventGuests = (await import('components/event_guests.connected')).default
    const windowHeight = window.document.documentElement.clientHeight
    render(
      (<AppContainer><EventGuests {...props} windowHeight={windowHeight} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/event_guests.connected', () => { doRender() })
  }
  doRender()
}

window.initEventTime = async (domEl, props) => {
  const doRender = async () => {
    const EventTime = (await import('components/event_time')).default
    render(
      (<AppContainer><EventTime {...props} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/event_time', () => { doRender() })
  }
  doRender()
}

window.initQuickSelector = async (domEl, props) => {
  const doRender = async () => {
    const QuickSelector = (await import('components/quick_selector.connected')).default
    render(
      (<AppContainer><QuickSelector {...props} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/quick_selector.connected', () => { doRender() })
  }
  doRender()
}

window.initEventLocationInput = async (domEl, props) => {
  const doRender = async () => {
    const EventLocationInput = (await import('components/event_location_input.connected')).default
    render(
      (<AppContainer><EventLocationInput {...props} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/event_location_input.connected', () => { doRender() })
  }
  doRender()
}

window.initStoryTextEdit = async (domEl, props) => {
  const ConnectedStoryTextEdit = (await import('components/story_text_edit.connected')).default
  render(<ConnectedStoryTextEdit {...props} />, domEl)
}

window.initStatusUpdateTextEdit = async (domEl, props) => {
  const ConnectedStatusUpdateTextEdit = (await import('components/status_update_text_edit.connected')).default
  render(<ConnectedStatusUpdateTextEdit {...props} />, domEl)
}

window.initImageGrid = async (domEl, props) => {
  const ImageGrid = (await import('components/image_grid')).default
  render(<ImageGrid {...props} />, domEl)
}

import Time from 'elements/time'
window.initTime = (domEl, props) => {
  render(<Time {...props} />, domEl)
}

import {RelativeTime} from 'elements/time'
window.initRelativeTime = (domEl, props) => {
  render(<RelativeTime {...props} />, domEl)
}

window.initEventAttendance = async (domEl, props) => {
  const EventAttendance = (await import('components/event_attendance.connected')).default
  render(<EventAttendance {...props} />, domEl)
}

window.initEmbedLinkImage = (domEl, props) => {
  const EmbedLinkImage = lazy(() => import('components/embed_link_image'))
  render(
    (
      <Suspense fallback={<Loader />}>
        <EmbedLinkImage {...props} />
      </Suspense>
    ), domEl
  )
}

window.initStatsModal = async (domEl, props) => {
  const StatsModalConnected = (await import('components/resource_metrics/stats_modal.connected')).default
  render(<StatsModalConnected {...props} />, domEl)
}

window.initToggleLanguageDropdown = async (domEl, props) => {
  const LanguageDropdownBox = (await import('components/language_dropdown_box.connected')).default
  render(<LanguageDropdownBox {...props} />, domEl)
}

import {
  initSignaturePanel,
  renderRequestSignatureModal,
  cancelSignatureRequest,
  renderResendSignatureRequestNotificationModal
} from 'apps/signature/entry'
window.initSignaturePanel = initSignaturePanel
window.renderRequestSignatureModal = renderRequestSignatureModal
window.cancelSignatureRequest = cancelSignatureRequest
window.renderResendSignatureRequestNotificationModal = renderResendSignatureRequestNotificationModal

import {
  initCookiesConsent
} from 'apps/cookies_consent/entry'
window.initCookiesConsent = initCookiesConsent

import { initMobileAppPrompt } from 'apps/mobile_app_prompt/entry'
window.initMobileAppPrompt = initMobileAppPrompt

window.killComponentAtDOMNode = (domEl) => {
  if (domEl) {
    unmountComponentAtNode(domEl)
  }
}

import {initTopBarCreation} from 'apps/tcc/entry'
window.initTopBarCreation = initTopBarCreation

import initGlobalSearch from 'apps/global_search/entry'
window.initGlobalSearch = initGlobalSearch

import ArticleIcon from 'elements/icon/article_mini'

window.articleIcon = (domEl, props) => {
  render(<ArticleIcon {...props} />, domEl)
}

window.reloadIfOverlayOpened = () => {
  if (document.querySelector('.overlay-open') !== null) {
    window.location.reload()
  }
}

import fileOverlay from 'components/file_overlay/entry'
window.fileOverlay = fileOverlay

import fileContent from 'components/file_content/entry'
window.fileContent = fileContent

import initiateHelp from 'apps/help'
document.addEventListener('DOMContentLoaded', () => initiateHelp())

window.initImageSelector = async (domEl, props) => {
  const ImageSelector = (await import('components/image_selector')).default
  render(<ImageSelector {...props} />, domEl)
}

import {initExport} from 'apps/export/entry'
window.initExport = initExport
