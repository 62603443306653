import React from 'react'
import ReactModal from 'react-modal'
import {buildTranslator} from 'utils/translator'

const t = buildTranslator(['global'])

const defaultModalStyle = {
  overlay: {
    zIndex: 1069,
    backgroundColor: 'rgba(38, 113, 171, 0.4)',
    overflow: 'scroll'
  },
  content: {
    top: 30,
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, 0)',
    padding: 0,
    overflow: 'visible',
    position: 'absolute',
    minWidth: 400
  }
}

const fullScreenModalStyle = {
  overlay: {
    zIndex: 1069,
    backgroundColor: 'rgba(38, 113, 171, 0.4)',
    overflow: 'scro0ll'
  },
  content: {
    borderWidth: 0,
    borderRadius: 0,
    top: 0,
    left: 0,
    padding: 0,
    overflow: 'visible',
    position: 'absolute',
    width: '100%',
    height: '100%'
  }
}

const Header = ({children, onRequestClose}) => {
  return (
    <div className='modal-header' style={{minHeight: 45}}>
      {onRequestClose && <button onClick={onRequestClose} className="close" title={t('Close')}>&times;</button>}
      {children}
    </div>
  )
}

const Modal = (props) => {
  const {style = {}, isOpen, onRequestClose, title, fullScreen = false, children} = props,
    header = props.header || <h3>{title}</h3>

  const baseStyle = fullScreen ? fullScreenModalStyle : defaultModalStyle

  const modalStyle = {
    overlay: {...baseStyle.overlay, ...style.overlay},
    content: {...baseStyle.content, ...style.content}
  }

  return (
    <ReactModal
      isOpen={isOpen}
      appElement={document.body}
      onRequestClose={!onRequestClose ? () => {} : onRequestClose}
      contentLabel='popup'
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={modalStyle}>
      <Header onRequestClose={onRequestClose}>{header}</Header>
      <div
        className="modal-body"
        style={{paddingTop: 4, ...style.body}}
        data-allow-over-overlay="1">
        {children}
      </div>
    </ReactModal>
  )
}

const ModalCompatibility = ({
  blockClose, onRequestClose,
  overlayStyle, contentStyle, bodyStyle, style = {},
  ...props
}) => {
  if (blockClose !== undefined) {
    console.warn("[Deprecation][Modal] Stop using 'blockClose'. Set 'onRequestClose' to false instead")
  }
  if (overlayStyle || contentStyle || bodyStyle) {
    console.warn("[Deprecation][Modal] Use a single 'style' attribute. It can contain 'overlay', 'content' and 'body' keys.")
  }

  return (
    <Modal
      onRequestClose={blockClose ? false : onRequestClose}
      style={{
        ...style,
        content:  {...(contentStyle || {}), ...(style.content || {})},
        body:     {...(bodyStyle || {}), ...(style.body || {})},
        overlay:  {...(overlayStyle || {}), ...(style.overlay || {})}
      }}
      {...props} />
  )
}

export default ModalCompatibility
