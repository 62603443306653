import React, {Children} from 'react'
import Spinner from 'react-loaders'

const WrappedLoader = ({style, type, children}) => (
  <div style={{
    textAlign: 'center',
    WebkitJustifyContent: 'center',
    justifyContent: 'center',
    WebkitAlignItems: 'center',
    alignItems: 'center',
    ...style
  }}>
    <Spinner type={type || "line-scale"} className="loader-default" />
    {children}
  </div>
)

class Loader extends React.PureComponent {

  constructor(props) {
    super(props)
    this.displayName = 'Loader'
  }

  static get defaultProps() {
    return {
      loaded: false,
    }
  }

  render() {
    if (this.props.children && this.props.loaded) {
      return Children.only(this.props.children)
    } else if (this.props.loaded) {
      return false
    } else {
      const {type, text: children, style} = this.props
      return <WrappedLoader type={type} children={children} style={style || {}} />
    }
  }
}

export default Loader
