import {render} from 'react-dom'
import ShortcutsModal from './components/shortcuts_modal'
import ensureDomElement from 'utils/ensure_dom_element'

export const initShortcuts = (props) => {
  const domEl = ensureDomElement('shortcutsModal')

  const load = () => {
    render(<ShortcutsModal  />, domEl)
  }

  if (module.hot) {
    module.hot.accept('./components/shortcuts_modal', load)
  }

  load()
}

export default initShortcuts
