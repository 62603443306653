import React, {Children, Component} from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import EnTranslations from 'translations/translations.en'
import FrTranslations from 'translations/translations.fr'
import NlTranslations from 'translations/translations.nl'
import DeTranslations from 'translations/translations.de'
import EsTranslations from 'translations/translations.es'
import PtTranslations from 'translations/translations.pt'
import PtBrTranslations from 'translations/translations.pt-BR'

const REACT_ELEMENT = Symbol.for("react.element")
const EMPTY_ARRAY = []

const isFlattenable = (value) => {
  const type = typeof value
  return type === "string" || type === "number"
}

const flatten = (array) => {
  if(array.every(isFlattenable)) {
    return array.join("")
  }
  return array
}

const toTemplate = (string) => {
  const expressionRE = /{{\w+}}/g
  const match = string.match(expressionRE) || EMPTY_ARRAY
  return [string.split(expressionRE), ...match]
}

const normalizeValue = (value, key) => {
  if(value == null || typeof value === "boolean") {
    return value
  }
  if(typeof value === "string" || typeof value === "number") {
    return value
  }
  if(value.$$typeof === REACT_ELEMENT) {
    return React.cloneElement(value, { key })
  }
}

const render = (string, values) => {
  const [parts, ...expressions] = toTemplate(string)
  return flatten(parts.reduce(
    (acc, item, index, array) => {
      if(index === array.length - 1) {
        return [
          ...acc,
          item,
        ]
      }
      const match = expressions[index] && expressions[index].match(/{{(\w+)}}/)
      const value = match != null ? values[match[1]] : null
      return [
        ...acc,
        item,
        normalizeValue(value, index),
      ]
    },
    []
  ))
}

const Translations = {
  en: EnTranslations,
  fr: FrTranslations,
  nl: NlTranslations,
  de: DeTranslations,
  es: EsTranslations,
  pt: PtTranslations,
  'pt-BR': PtBrTranslations
}

const defaultTranslationLookup = (key) => {
  return key.split('|')[0]
}

const defaultTranslator = (key, params) => {
  console.error('Missing translation context')
  return render(defaultTranslationLookup(key), params)
}

var missingKeys = []
const logMissingTranslation = (key) => {
  if (!missingKeys.includes(key)) {
    missingKeys.push(key)
    console.error(`Missing js translation for key "${key}"`)
  }
}

const defaultLocale = 'en-US'


const getLocaleFromDom = () => {
  let lang = document.getElementsByTagName('html')[0].getAttribute('lang') || defaultLocale

  if (Translations.hasOwnProperty(lang)) {
    return lang
  }

  // eslint-disable-next-line prefer-destructuring
  lang = lang.split('-')[0]

  if (Translations.hasOwnProperty(lang)) {
    return lang
  }

  return defaultLocale
}

const createTranslator = (keys) => {
  return (componentNames) => {
    var componentKeys

    if (!Array.isArray(componentNames)) {
      componentNames = [componentNames]
    }

    componentKeys = keys.global

    componentNames.reverse().forEach(key => {
      if (keys[key]) {
        componentKeys = update(componentKeys, {$merge: keys[key]})
      }
    })

    return (key, params) => {
      let translation = componentKeys[key]

      if (!translation) {
        logMissingTranslation(key)
        translation = defaultTranslationLookup(key)
      }

      if(Array.isArray(translation)) {
        // plural
        if (params != null && typeof params.n === "number") {
          translation = translation[pluralType(params.n)]
        }
        else {
          return render(translation.join("\n"), params)
        }
      }

      return render(translation, params)
    }
  }
}

class TranslationStore extends Component {

  getChildContext() {
    const {translations, locale} = this.props,
      data = translations[locale] || translations.en,
      translator = createTranslator(data)

    return {translator, locale}
  }

  render() {
    return Children.only(this.props.children)
  }

}

TranslationStore.childContextTypes = {
  translator: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
}

TranslationStore.propTypes = {
  translations: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired
}

class Translator extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locale: getLocaleFromDom()
    }
  }

  render() {
    return (
      <TranslationStore translations={Translations} locale={this.state.locale}>
        {this.props.children}
      </TranslationStore>
    )
  }

}

const translate = (displayName) => {
  let t

  return (ChildComponent) => {
    class TranslatedComponent extends Component {
      render() {
        const {translator, locale} = this.context
        t = translator ? translator(displayName) : defaultTranslator

        return <ChildComponent {...this.props} t={t} locale={locale} />
      }
    }

    TranslatedComponent.contextTypes = {
      translator: PropTypes.func,
      locale: PropTypes.string
    };

    return TranslatedComponent
  }
}

const buildTranslator = (namespaces) => {
  const translations = Translations[getLocaleFromDom()] || Translations.en
  return createTranslator(translations)(namespaces)
}

export {Translator, translate, buildTranslator, getLocaleFromDom}
export default translate
