import localforage from 'localforage'

export const save = (app, label, value) => {
  return fetchStore(app).setItem(label, value)
}

export const load = (app, label) => {
  return fetchStore(app).getItem(label)
}

var STORES = {}

const fetchStore = (name) => {
  const store = STORES[name]
  if (store) return store

  const newStore = localforage.createInstance({
    name,
    version: 1.0,
    storeName: 'actor_settings',
    description: 'Settings auto persisted by the app'
  })

  STORES[name] = newStore

  return newStore
}
