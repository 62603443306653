const signatures = async() => {
  return await import('./index')
}

export const initSignaturePanel = async(domEl, props = {}) => {
  (await signatures()).renderSignaturePanel({...props, domEl})
}

export const renderRequestSignatureModal = async({resourceId, preloaded = false}) => {
  (await signatures()).renderRequestSignatureModal({resourceId, preloaded})
}

export const cancelSignatureRequest = async(resourceId) => {
  (await signatures()).onCancelSignatureRequest({resourceId})
}

export const renderResendSignatureRequestNotificationModal = async(resourceId) => {
  (await signatures()).renderResendSignatureRequestNotificationModal({resourceId})
}
