import {render} from 'react-dom'
import SearchBar from './search'

const goToSearch = (query) => {
  if (query && query !== '') {
    window.location.href = `/search?utf8=✓&query=${query}`
  } else {
    window.location.href = `/search`
  }
}
const goToResult = ({id}) => {
  window.location.href = `/resources/${id}`
}

export const initGlobalSearch = ({domEl, ...props}) => {
  const load = () => {
    render(
      <SearchBar onSearch={goToSearch} onSelectResult={goToResult} />,
      domEl
    )
  }

  if (module.hot) {
    module.hot.accept('./search', load)
  }

  load()
}

export default initGlobalSearch
