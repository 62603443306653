import {unmountComponentAtNode} from 'react-dom'

const buildDomElement = ({id, tag, buildNode}) => {
  const domEl = document.createElement(tag)
  buildNode(domEl)
  domEl.setAttribute('id', id)
  return domEl
}

const ensureDomElement = (id, {tag = 'div', unmount = true, buildNode = (node) => {}} = {}) => {
  let domEl = document.getElementById(id)

  if (!domEl) {
    domEl = buildDomElement({id, tag, buildNode})
    document.body.appendChild(domEl)
  }

  return domEl
}

export default ensureDomElement
