export const dropError = () => {}

const eatTypeErrorCancelled = (f) => (e) => {
  if (e.name === 'TypeError' && e.message === 'cancelled') {
    // Browser is reloading
    console.log('fetch interuppted for browser reload')
  } else {
    f(e)
  }
}

// Maybe throw with this => https://www.npmjs.com/package/app-error
export const handleMiddlewareError = (e) => { throw e }

export const handleComponentError = (description, callback) => eatTypeErrorCancelled(
  error => {
    console.error("ComponentError: " + description)
    console.error(error)
    if (callback) callback(error)
  }
)

export const handleActionError = (description, callback) => eatTypeErrorCancelled(
  error => {
    console.error("ActionError: " + description)
    console.error(error)
    if (callback) callback(error)
  }
)
