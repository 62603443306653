import {render} from 'react-dom'
import {AppContainer} from 'react-hot-loader'
import ProfileApp from 'components/profile/app'

const initProfileApp = (domEl = null, props = null) => {
  const doRender = () => {
    render(
      (<AppContainer><ProfileApp {...props} /></AppContainer>),
      domEl
    )
  }
  if (module.hot) {
    module.hot.accept('components/profile/app', () => { doRender() })
  }
  doRender()
}


export default initProfileApp
